// import React from 'react';
import {
    Home,
    HomeWork,
    ViewCompact,
    Notifications,
    CalendarToday,
    Person,
    AccountCircle,
    Settings,
    ExitToApp,
    Search,
    Menu,
    Build,
    AccountBalance,
    MoreHoriz,
    Hotel,
    Bathtub,
    Weekend,
    Description,
    Map,
    Visibility,
    // Forum,
    LocalOffer,
    Note,
    ChevronLeft,
    ChevronRight,
    MoreVert,
    ExpandMore,
    Assignment,
    // AccountBalanceWallet,
    Construction,
    Opacity,
    Power,
    Fireplace,
    Warning,
    BarChart,
    Facebook,
    Feedback,
    Twitter,
    LinkedIn,
    Payment,
    Tune,
    Launch,
    Email,
    PendingActions,
    Favorite,
    Info,
    ArrowBack,
} from '@mui/icons-material';

export default {
    "props": {
        "backToWebsiteIcon": Launch,
        "dashboardIcon": Home,
        "myPropertyIcon": ViewCompact,
        "inboxIcon": Notifications,
        "notificationIcon": Notifications,
        "calendarIcon": CalendarToday,
        "profileIcon": Person,
        "searchIcon": Search,
        "settingsIcon": Settings,
        "newsIcon": Description,
        "logoutIcon": ExitToApp,
        "mobileMenuIcon": MoreHoriz,
        "mobileBurgerIcon": Menu,
        "propBedsIcon": Hotel,
        "propBathIcon": Bathtub,
        "propRecIcon": Weekend,
        "tenancyIcon": HomeWork,
        "overviewTabIcon": Home,
        "documentsIcon": Description,
        "mapIcon": Map,
        "appointmentsIcon": Visibility,
        "feedbackIcon": Feedback,
        "offerIcon": LocalOffer,
        "noteIcon": Note,
        "faultsIcon": Build,
        "transactionsIcon": AccountBalance,
        "chevronLeftIcon": ChevronLeft,
        "chevronRightIcon": ChevronRight,
        "accountIcon": AccountCircle,
        "expandMoreIcon": ExpandMore,
        "moreIcon": MoreVert,
        "inspectionActionIcon": Assignment,
        "tenancyOfferIcon": Home,
        "viewingIcon": Visibility,
        "worksOrderIcon": Build,
        "utilitiesIcon": Construction,
        "waterIcon": Opacity,
        "powerIcon": Power,
        "gasIcon": Fireplace,
        "errorPage": Warning,
        "marketingPerformance": BarChart,
        "facebookIcon": Facebook,
        "shareFacebook": Facebook,
        "shareTwitter": Twitter,
        "shareLinkedIn": LinkedIn,
        "paymentIcon": Payment,
        "filterResults": Tune,
        "emailIcon": Email,
        "logoIcon": Warning,
        "backButtonArrow": ChevronLeft,
        "noPendingActions": PendingActions,
        "pendingActionsIcon": PendingActions,
        "propertyNotFound": Visibility,
        "savedPropertiesIcon": Favorite,
        "savedSearchIcon": Search,
        "recentActivitiesIcon": Info,
        "arrowBack": ArrowBack,
    },
    "sx": {
    },
}
