import React from "react";
import { withSubtheme } from '@starberryComponentsMui';

import defaults from './defaults';

import { Error as ErrorIcon } from '@mui/icons-material';

export const StarberryIcons = withSubtheme((props) => {
    const {
        className,
        iconName,
        fontSize,
        iconStyle,
        ...rest
    } = props;

    if (undefined !== rest[iconName]) {
        const Icon = rest[iconName];
        return <Icon className={className} style={iconStyle} fontSize={fontSize} />;
    }

    console.warn(`Icon ${iconName} not found`);
    return <ErrorIcon classes={{root: className}} />;

}, "projectIcons", defaults);

export default StarberryIcons;
